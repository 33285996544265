/* You can add global styles to this file, and also import other style files */
/* added from project library*/


@import './styles/variables.scss';

:root {
    font-weight: bold;
    font-family: 'Inter';
    font-size: var(--typography-size-base);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    border-radius: var(--default-element-radius);
    background-color: var(--secondary-background-color);
    border: 1px solid var(--borders-color);

    .elements-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px;
    }

    .elements-wrapper>* {
        margin: 0;
    }
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.tooltip-container {
    text-align: center;
    z-index: 112;
    position: fixed;
    padding: 8px 16px;
    border: thin solid var(--borders-color);
    border-radius: var(--default-element-radius);
    font-size: 0.66rem;
    font-weight: 600;
    font-family: inherit;
    width: auto;
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
    box-sizing: border-box;
    pointer-events: none;
}


/* END -  added from project library*/
body {
    margin: 0;

    div,
    span {
        color: var(--primary-text-color);
    }
}

.border-container {
    border: thin solid var(--borders-color);
    border-radius: var(--default-element-radius);
}

.border-card {
    border: thin solid var(--borders-color);
    border-radius: var(--default-element-radius);
    background-color: var(--secondary-background-color);
    padding: 1rem;
    box-sizing: border-box;
}

.code-snippet-style {
    font-family: Inter !important;
}







.text-danger {
    text-align: start;
    margin-top: 0.5rem;
    margin-left: 2rem;
    color: var(--color-orange) !important;
    font-size: .7rem;
    white-space: pre-wrap;
}

.mat-icon {
    height: 100% !important;
    width: 100% !important;
    max-height: 20px !important;
    max-width: 20px !important;
}